<template>
  <lazy-hydrate
    v-slot="{ hydrated }"
    :on-interaction="['click', 'touchstart', 'scroll']"
  >
    <div>
      <topbar />
      <div>
        <header-component no-content />
        <drawer-menu v-if="!$device.isDesktop" />
        <nuxt />
        <hr />
        <footer-component v-if="hydrated" no-content />
      </div>
    </div>
  </lazy-hydrate>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import HeaderComponent from '@/components/header/Header.vue'
import Topbar from '~/components/Topbar.vue'

const FooterComponent = () =>
  import(
    /* webpackChunkName: "no-content-footer"  */ '~/components/footer/Footer.vue'
  )
const DrawerMenu = () =>
  import(
    /* webpackChunkName: "drawer-menu"  */ '~/components/header/header-drawer/HeaderDrawer.vue'
  )

export default {
  layout: 'default',
  components: {
    LazyHydrate,
    HeaderComponent,
    FooterComponent,
    DrawerMenu,
    Topbar,
  },
  head() {
    return {
      bodyAttrs: {
        class: 'goasyourself',
      },
    }
  },
}
</script>
