// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/icons/icons-regular/selo_100-seguro-retina.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/icons-regular.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-d84dce6e]:root{--light_text_color:#666}img.selo_100-seguro[data-v-d84dce6e]{width:88px;height:28px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;background-size:contain}img.selo-eureciclo[data-v-d84dce6e]{width:60px;height:51px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") -3260px -41px no-repeat}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
