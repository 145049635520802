import { initialStoreConfigs } from '@/plugins/store-setter'
import copyObject from '@/commun/utils/copyObject'
import filterGlobalDevice from '@/commun/utils/filterGlobalDevice'
import { setI18nLanguage } from '../plugins/i18n'
import isPresent from '~/utils/isPresent'
import isValidNumber from '~/commun/utils/isValidNumber'
import appendUrlParam from '~/utils/appendUrlParam'
import Cookie from '~/utils/Cookie'

import {
  STORE_CODES,
  CURRENCIES,
  LOCALES,
  LOCALE_COOKIE_NAME,
  CURRENCY_COOKIE_NAME,
} from '~/config'

export const state = () => ({
  locale: 'pt-BR',
  currency: 'BRL',
  storeCode: 'br',
  cartQty: 0,
  siteStore: null,
  orderNumber: '',
  user: null,
  currentHost: '',
  currentClickedProductId: null,
  checkingUser: true,
  currencyFromUrl: false, // boolean
  localeFromUrl: false, // boolean
  substoreFromUrl: null, // string
  countryCode: null,
  cloudFlareCountryCode: null,
  substore: { active: false },
  globalDevice: null,
  filteredGlobalDevice: null,
  userInteracted: false,
  isBlackFriday: false,
  isChristmas: false,
  isGocaseBirth: true,
  skipStoreValidation: false,
  isUsStore: false,
})

export const mutations = {
  SET_BF(state, active) {
    state.isBlackFriday = active
  },
  SET_CHRISTMAS(state, active) {
    state.isChristmas = active
  },
  SET_SUBSTORE(state, substores = {}) {
    // eslint-disable-next-line prefer-const
    const { countryCode, substoreFromUrl } = state

    let substore = { active: false }

    for (const substoreCode in substores) {
      if (
        substoreCode === substoreFromUrl?.toLowerCase() ||
        // eslint-disable-next-line camelcase
        substores[substoreCode]?.supported_countries?.includes(countryCode)
      ) {
        substore =
          substores === substoreFromUrl
            ? substoreFromUrl
            : substores[substoreCode]
        substore.code = substoreCode
        substore.active = true

        break
      }
    }

    state.substore = substore
  },
  SET_CART_QTY(state, quantity) {
    if (isValidNumber(quantity)) state.cartQty = parseInt(quantity)
  },
  /** Do not use this mutations. Use changeLocale action insead! */
  SET_LOCALE(state, locale) {
    setI18nLanguage(locale)
    state.locale = locale

    if (process.client) {
      const objectLocaleCookie = {}
      objectLocaleCookie[LOCALE_COOKIE_NAME] = state.locale
      objectLocaleCookie.path = '/'

      if (this.$config.env === 'production') {
        objectLocaleCookie.domain = '.gocase.com'
      }

      Cookie.Client().set(objectLocaleCookie)
    }
  },
  SET_CURRENCY(state, payload) {
    const currency = payload?.toUpperCase()

    if (CURRENCIES.includes(currency)) {
      state.currency = currency

      if (process.client) {
        const objectCurrencyCookie = {}
        objectCurrencyCookie[CURRENCY_COOKIE_NAME] = state.currency
        objectCurrencyCookie.path = '/'

        if (this.$config.env === 'production') {
          objectCurrencyCookie.domain = '.gocase.com'
        }

        Cookie.Client().set(objectCurrencyCookie)
      }
    }
  },
  SET_TTCLID(_state, { ttclid, ctx }) {
    const expiresDays = 7
    const date = new Date()
    date.setDate(date.getDate() + expiresDays)

    const cookie = Cookie.Server(ctx).set({
      _ttclid: ttclid,
      path: '/',
      domain: ctx.$isBrStore ? '.gocase.com.br' : '.gocase.com',
      expires: date,
      HttpOnly: true,
    })

    ctx.res.setHeader('Set-Cookie', cookie)
  },
  SET_GLOBAL_DEVICE(state, globalDevice) {
    state.globalDevice = globalDevice
  },
  SET_FILTERED_GLOBAL_DEVICE(state, filteredGlobalDevice) {
    state.filteredGlobalDevice = filteredGlobalDevice
  },
  SET_CONFIGS_FROM_URL(
    state,
    { localeFromUrl, currencyFromUrl, substoreFromUrl }
  ) {
    state.localeFromUrl = localeFromUrl
    state.currencyFromUrl = currencyFromUrl
    state.substoreFromUrl = substoreFromUrl
  },
  SET_SITE_STORE(state, store) {
    if (isPresent(store)) {
      const storeCode = store.locale === 'pt-BR' ? 'br' : 'global'

      state.siteStore = store

      // eslint-disable-next-line no-console

      this.commit('SET_CURRENCY', store.currency)
      this.commit('SET_STORE_CODE', storeCode)
      this.commit('SET_LOCALE', store.locale)
    }
  },
  SET_STORE_CODE(state, storeCode) {
    if (STORE_CODES.includes(storeCode)) {
      state.storeCode = storeCode
    }
  },
  SET_USER_INTERACTED(state) {
    state.userInteracted = true
  },
  SET_ORDER_NUMBER(state, number) {
    state.orderNumber = number
  },
  SET_USER(state, user) {
    state.user = user

    this.commit('UPDATE_ORDER_INFO', user?.order)
  },
  SET_GUEST_ORDER(state, guestOrder) {
    state.user.guestOrder = guestOrder
  },
  UPDATE_ORDER_INFO(_state, info) {
    if (info?.number) this.commit('SET_ORDER_NUMBER', info.number)
    if (info?.quantity) this.commit('SET_CART_QTY', info.quantity)
  },
  SET_CURRENT_HOST(state, currentHost) {
    state.currentHost = currentHost
  },
  CURRENT_CLICKED_PRODUCT_ID(state, productId) {
    state.currentClickedProductId = productId
  },
  CHECKING_USER(state, checkingUser) {
    state.checkingUser = checkingUser
  },
  SET_COUNTRY_CODE(state, countryCode) {
    state.countryCode = countryCode
  },

  SET_CLOUDFLARE_COUNTRY_CODE(state, countryCode) {
    state.cloudFlareCountryCode = countryCode
  },

  SET_SKIP_STORE_VALIDATION(state, skipStoreValidation) {
    state.skipStoreValidation = skipStoreValidation
  },
  SET_US_STORE(state, isUsStore) {
    state.isUsStore = isUsStore
  },
}

export const actions = {
  // See Docs: https://nuxtjs.org/guide/vuex-store#the-nuxtserverinit-action
  async nuxtServerInit({ commit, dispatch }, ctx) {
    const host = ctx.req?.headers?.host
    let countryCode = ctx.req?.headers?.['cf-ipcountry']

    const cloudFlareCountryCode = ctx.req?.headers?.['cf-ipcountry']
    commit('SET_CLOUDFLARE_COUNTRY_CODE', cloudFlareCountryCode)

    const splitedHost = host?.split('.')
    const subdomain = splitedHost?.[0]

    const isUsDomain = subdomain && subdomain.match('us')

    if (isUsDomain) dispatch('setUsStore')

    const usHosts = {
      development: 'http://us-dev.gocase.com:3000',
      staging: 'https://us-staging.gocase.com',
      production: 'https://us.gocase.com',
    }
    const globalFrontHosts = {
      development: 'http://dev.gocase.com:3000',
      staging: 'https://v4-staging.gocase.com',
      production: 'https://www.gocase.com',
    }

    const usHost = usHosts[this.$config?.env || 'development']
    const globalFrontHost = globalFrontHosts[this.$config?.env || 'development']

    // TO DEACTIVATE THIS SUBSTORE
    // const forceRedirectWwwGocase = countryCode !== 'BR'
    // const redirectUstoUsWebsite = false

    // TO ACTIVATE THIS SUBSTORE
    const forceRedirectWwwGocase = false
    const redirectUstoUsWebsite = !isUsDomain

    const globalGenericWebsite =
      host.match(`${globalFrontHost.split('//')[1]}/`) ||
      host === `${globalFrontHost.split('//')[1]}`

    if (isUsDomain && countryCode && forceRedirectWwwGocase) {
      ctx.redirect(302, `${globalFrontHost}${ctx.route.fullPath}`)
    } else if (host.match('gocase.com.br')) {
      countryCode = 'BR'
    } else if (
      countryCode === 'US' &&
      redirectUstoUsWebsite &&
      globalGenericWebsite
    ) {
      ctx.redirect(302, `${usHost}${ctx.route.fullPath}`)
    }

    if (isUsDomain) {
      countryCode = 'US'
    }

    commit('SET_FILTERED_GLOBAL_DEVICE', filterGlobalDevice(ctx.req.headers))
    dispatch('setSkipStoreValidation', ctx)

    if (process.server && Object.keys(ctx?.route?.query || {})?.length) {
      const { ttclid } = ctx.route.query
      if (ttclid) commit('SET_TTCLID', { ttclid, ctx })
    }

    commit(
      'SET_CURRENT_HOST',
      host
        ? 'https://' + host
        : `https://www.gocase.com${ctx.$isBrStore ? '.br' : ''}`
    )

    commit('SET_COUNTRY_CODE', countryCode)

    /**
     * Newrelic transation naming
     */
    // if (process.newrelic) {
    //   const transationName =
    //     `${ctx.route?.matched?.[0]?.path} (${ctx.route?.name})`.replace(
    //       /^\//,
    //       ''
    //     ) || ctx.req?.path
    //   process.newrelic.setTransactionName(transationName)

    //   let innerNrBrowserHtml = process.newrelic.getBrowserTimingHeader()
    //   const scriptTagRegexp = /(<script.*?>)|(<\/script>)/g
    //   const links = innerNrBrowserHtml.matchAll(scriptTagRegexp)

    //   for (const match of links) {
    //     innerNrBrowserHtml = innerNrBrowserHtml.replace(match?.[0], '')
    //   }

    //   ctx.app.head.script.push({
    //     hid: 'newrelic',
    //     innerHTML: innerNrBrowserHtml,
    //     type: 'text/javascript',
    //   })

    //   if (!ctx.app.head.__dangerouslyDisableSanitizersByTagID) {
    //     ctx.app.head.__dangerouslyDisableSanitizersByTagID = {}
    //   }
    //   ctx.app.head.__dangerouslyDisableSanitizersByTagID.newrelic = [
    //     'innerHTML',
    //   ]
    // }

    const apiRequests = onServerInitCalls.map((fn) => fn.call(this, ctx))

    try {
      await Promise.all(apiRequests)
    } catch (err) {
      ctx.error({
        statusCode: 404,
        message: `Failed to get Store. Please retry reloading this page`,
      })

      ctx.$sentry.captureException(err)
    }
  },
  setBf({ commit }, active) {
    commit('SET_BF', active)
  },
  setChristmas({ commit }, active) {
    commit('SET_CHRISTMAS', active)
  },
  setUsStore({ commit }) {
    commit('SET_US_STORE', true)
  },
  setSkipStoreValidation({ commit }, ctx) {
    const { sps, inpr } = ctx.route.query
    const spsCookie = Cookie.IndependentContext(ctx).get('sps')
    if (sps || inpr || spsCookie) {
      if (sps || inpr) {
        const expiresDays = 60
        const date = new Date()
        date.setDate(date.getDate() + expiresDays)

        const cookie = Cookie.Server(ctx).set({
          sps: '1',
          path: '/',
          domain: ctx.$isBrStore ? '.gocase.com.br' : '.gocase.com',
          expires: date,
          HttpOnly: false,
        })

        ctx.res.setHeader('Set-Cookie', cookie)
      }

      commit('SET_SKIP_STORE_VALIDATION', !!(sps || spsCookie))
    }
  },
  /** Fetch site Store with locale, currency, menus, global message, etc. */
  async fetchSiteStore(
    { commit, dispatch, state },
    { code, locale, currency, currencyFromUrl, localeFromUrl, substoreFromUrl }
  ) {
    const apiUrl = `/stores/${code}?locale=${locale}`
    const { store } = await this.$axios.$get(apiUrl, {
      cache: {
        readOnError: true,
        ignoreCache: false,
      },
    })

    commit('SET_CONFIGS_FROM_URL', {
      currencyFromUrl,
      localeFromUrl,
      substoreFromUrl,
    })

    commit('SET_SUBSTORE', store.substores)

    if (currency && code !== 'br') {
      store.currency = currency
      if (locale) store.locale = locale
    }

    commit('SET_SITE_STORE', store)

    try {
      await dispatch('changeLocale', store.locale)
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },
  async checkUserLogin(
    { commit, state, dispatch, getters },
    { ctx = null, force = false } = {}
  ) {
    const params = {
      ...getters.storeInfo,
    }

    if (!force && getters.isLogged) {
      commit('CHECKING_USER', false)

      if (state.storeCode === 'br') return state.user
    } else {
      commit('CHECKING_USER', true)
    }

    try {
      const user = await this.$axios.$get(
        appendUrlParam('/users/login', params),
        {
          headers: {
            Accept: 'application/json, charset=utf-8',
          },
          cache: {
            ignoreCache: true,
          },
        }
      )

      if (state.storeCode === 'global' && this.$config.env === 'production') {
        let currencyCookie = Cookie.IndependentContext(ctx).get(
          CURRENCY_COOKIE_NAME
        )
        let localeCookie = Cookie.IndependentContext(ctx).get(
          LOCALE_COOKIE_NAME
        )

        if (!currencyCookie) {
          commit('SET_CURRENCY', state.currency)

          currencyCookie = Cookie.IndependentContext(ctx).get(
            CURRENCY_COOKIE_NAME
          )
        }

        if (!localeCookie) {
          commit('SET_LOCALE', state.locale)

          localeCookie = Cookie.IndependentContext(ctx).get(LOCALE_COOKIE_NAME)
        }

        if (state.currency !== currencyCookie) {
          commit(
            'SET_CURRENCY',
            state.currencyFromUrl || currencyCookie || state.currency
          )
        }

        if (state.locale !== localeCookie) {
          await dispatch(
            'changeLocale',
            state.localeFromUrl || localeCookie || state.locale
          )
        }
      }

      if (!force && getters.isLogged) {
        return state.user
      }

      commit('SET_USER', user)
      this.$sentry?.setUser(user || {})

      commit('UPDATE_ORDER_INFO', user?.order)

      this.$userResolve(copyObject(user))

      return user
    } catch (error) {
      this.$userResolve(null)
      return null
    } finally {
      commit('CHECKING_USER', false)
    }
  },
  setGlobalDevice({ commit }, globalDevice) {
    commit('SET_GLOBAL_DEVICE', globalDevice)
  },
  async logout({ commit, state }) {
    try {
      const params = {
        store: state.storeCode,
        locale: state.locale,
        substore: state.substore.code,
      }

      await this.$axios.$get(appendUrlParam('/users/logout', params))
      this.$sentry.setUser({})
      commit('SET_USER', null)

      window.location.replace('/')

      return true
    } catch {
      return false
    }
  },
  changeLocale({ commit }, locale) {
    const messages = require(`~/locales/${locale}.json`)
    this.app.i18n.setLocaleMessage(locale, messages)
    this.app.i18n.locale = locale
    commit('SET_LOCALE', locale)
  },
  updateGuestOrder({ commit }, guestOrder) {
    return commit('SET_GUEST_ORDER', guestOrder)
  },
}

export const getters = {
  storeInfo({ locale, currency, storeCode, substore }) {
    const info = {
      locale: locale || 'en',
      currency,
      store: storeCode,
    }

    if (substore?.code) info.substore = substore?.code

    return info
  },
  sessionInfo(
    { locale, currency, storeCode, user },
    { isLogged, storeInfo, userInfo }
  ) {
    return {
      user: userInfo,
      ...storeInfo,
    }
  },
  userInfo({ user }, { isLogged }) {
    const userInfo = {
      email: user?.email,
      richRelevanceSessionId: user?.rich_relevance_session_id, // eslint-disable-line camelcase
      internal_id: user?.internal_id, // eslint-disable-line camelcase
      firstName: user?.first_name, // eslint-disable-line camelcase
      lastName: user?.last_name, // eslint-disable-line camelcase
      country_name: user?.country_name, // eslint-disable-line camelcase
      country_id: user?.country_id, // eslint-disable-line camelcase
      phone_number: user?.phone_number, // eslint-disable-line camelcase
    }
    if (isLogged) {
      userInfo.id = user?.id
      userInfo.newsletter = user?.newsletter
      // eslint-disable-next-line camelcase
      userInfo.rewardsCredentials = user?.rewards_credentials
      userInfo.birth = user?.birth
      userInfo.gender = user?.gender
      userInfo.loggedIn = true
    } else {
      userInfo.loggedIn = false
    }

    return userInfo
  },
  // whether user is logged in or not
  isLogged({ user }) {
    // eslint-disable-next-line camelcase
    return user?.logged_in === true
  },
}

// Call this functions with VuexStore as this.
// First params is nuxt context.
// Each function must be async.
const onServerInitCalls = [
  /** Fetch stie store (loja) */
  async function fetchStore({
    req,
    $storeCode,
    $locale,
    $currency,
    $currencyFromUrl,
    $localeFromUrl,
    $substoreFromUrl,
    $localeFromCookie,
  }) {
    let locale = $locale
    const countryCode = this.state?.countryCode?.toLowerCase()
    if ($storeCode === 'br') {
      locale = 'pt-BR'
    } else if (LOCALES.includes(countryCode) && !$localeFromCookie) {
      locale = countryCode
    }

    if (!$storeCode) {
      const defaultStore = initialStoreConfigs(req)
      $storeCode = defaultStore.storeCode
      locale = defaultStore.locale
      $currency = defaultStore.currency
    }

    this.commit('SET_SITE_STORE', {
      code: $storeCode,
      locale,
      currency: $currency,
    })

    await this.dispatch('fetchSiteStore', {
      code: $storeCode,
      locale,
      currency: $currency,
      currencyFromUrl: $currencyFromUrl,
      localeFromUrl: $localeFromUrl,
      substoreFromUrl: $substoreFromUrl,
    })
  },
  // async function checkLogin(ctx) {
  //   await this.dispatch('checkUserLogin', { ctx, force: true })
  // },
  async function pageData(ctx) {
    const PageComponent = ctx.route?.matched?.[0]?.components?.default
    const asyncDataCall = PageComponent?.options?.asyncData

    if (PageComponent?.options?.preFetchAsyncData && asyncDataCall) {
      ctx.app._EVIL_DONT_DO_asyncDataPrefetch = await asyncDataCall(ctx)
    }
  },
]
