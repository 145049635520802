<template>
  <div class="container">
    <div class="content">
      <div class="page-header">
        <h1>{{ title }}</h1>
      </div>
      <p class="center section text--large">
        {{ description }}
      </p>
      <p class="center section">
        <v-button
          :label="$t('error_pages.not_found.action')"
          type="submit"
          class="btn btn--success cart__totals-checkout"
          @click="goToHome"
        />
      </p>
    </div>
  </div>
</template>

<script>
import VButton from '~/components/v-button/VButton.vue'
import pushDataLayer from '~/mixins/gtm/push-data-layer'

export default {
  components: {
    VButton,
  },
  mixins: [pushDataLayer],
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    errorType: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.errorType === 'not_found') {
      this.pushDataLayer({
        event: 'genericEvent',
        eventCategory: 'navigation',
        eventAction: 'notFoundPageView',
        eventValue: {},
      })
    }
  },
  methods: {
    goToHome() {
      window.location =
        (window.location.protocol === 'https' ? 'https://' : 'http://') +
        window.location.host
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/_section.scss';
@import '~/assets/css/_content.scss';
@import '~/assets/css/container.scss';
</style>
