<template>
  <div>
    <topbar />
    <header-component :no-content="!loading" />

    <lazy-hydrate :trigger-hydration="$store.state.drawer.drawerOpened">
      <drawer-menu v-if="!$device.isDesktop" />
    </lazy-hydrate>

    <div class="error-page">
      <div v-if="loading">
        <logo-loading />
      </div>
      <page-errors-template
        v-else
        :title="$t(`error_pages.${errorType}.title`)"
        :description="$t(`error_pages.${errorType}.description`)"
        :error-type="errorType"
      />
    </div>

    <hr />
    <lazy-hydrate v-slot="{ hydrated }" when-visible>
      <div class="lazy_hydrate">
        <footer-component v-if="hydrated" :no-content="!loading" />
      </div>
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import HeaderComponent from '@/components/header/Header.vue'
import LogoLoading from '~/components/loadings/logo'
import PageErrorsTemplate from '~/components/pages/PageErrorsTemplate'

import urlCouponApplier from '~/mixins/url-coupon-applier'

import Topbar from '~/components/Topbar.vue'

const DrawerMenu = () =>
  import(
    /* webpackChunkName: "drawer-menu"  */ '~/components/header/header-drawer/HeaderDrawer.vue'
  )
const FooterComponent = () =>
  import(/* webpackChunkName: "footer"  */ '~/components/footer/Footer.vue')

export default {
  name: 'ErrorPage',
  layout: 'empty',
  components: {
    PageErrorsTemplate,
    LogoLoading,
    LazyHydrate,
    Topbar,
    HeaderComponent,
    DrawerMenu,
    FooterComponent,
  },
  mixins: [urlCouponApplier],
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  async fetch() {
    if (this.errorType === 'not_found') {
      const ctx = this.$nuxt.context
      const path = ctx.route.path

      const params = {
        ...ctx.store.getters.storeInfo,
        path,
      }

      this.checkIfIsToRedirect(ctx)

      try {
        const { new_path: newPath } = await ctx.$axios.$get(
          'seo_redirect/get_new_path',
          {
            params,
          }
        )

        if (path === newPath) {
          throw new Error(
            `Recursive Error, this seo redirect page probably is not working: ${newPath}`
          )
        }

        ctx.redirect(301, newPath)
      } catch (err) {
        this.loading = false
      }
    } else {
      this.loading = false
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    errorType() {
      return this.error.statusCode === 404 ? 'not_found' : 'exception'
    },
    isMobileFixedMockup() {
      return this.$device.isMobile && this.$route.name === 'slug-p-case_device'
    },
  },
  methods: {
    checkIfIsToRedirect(ctx) {
      const path = ctx.route.path
      const fullPath = ctx.route.fullPath

      const blacklistRedirects =
        // eslint-disable-next-line camelcase
        this.$store?.state?.siteStore?.blacklist_redirects || []

      const inBlackListRedirects =
        blacklistRedirects.includes(path) ||
        blacklistRedirects.includes(path.slice(1))

      if (inBlackListRedirects) {
        const redirectUrl = this.$config.baseBrowserURL + fullPath
        ctx.redirect(301, redirectUrl)
      }
    },
  },
  head() {
    return {
      bodyAttrs: {
        class: [
          this.isMobileFixedMockup ? 'static-header' : 'initial-search-visible',
        ],
      },
    }
  },
}
</script>

<style lang="scss">
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 500px;
}
</style>
