import { i18n } from '~/plugins/i18n'

export const state = () => ({
  lastCategories: {},
  isLoading: false,
  smartRedirectPath: '',
  suggestions: [],
  popularSearches: [],
})

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.lastCategories = categories
  },
  SET_LOADING(state, boolean) {
    state.isLoading = boolean
  },
  SET_SMART_REDIRECT_PATH(state, path) {
    state.smartRedirectPath = path
  },
  SET_SUGGESTIONS(state, suggestions) {
    state.suggestions = suggestions
  },
  CLEAR_SUGGESTIONS(state) {
    state.suggestions = []
  },
  SET_POPULAR_SEARCHES(state, popularSearches) {
    state.popularSearches = popularSearches
  },
  CLEAR_POPULAR_SEARCHES(state) {
    state.popularSearches = []
  },
}

export const actions = {
  updateLastCategories({ commit }, categories) {
    commit('SET_CATEGORIES', categories)
  },
  setLoading({ commit }, boolean) {
    commit('SET_LOADING', boolean)
  },
  updateSmartRedirectPath({ commit }, path) {
    commit('SET_SMART_REDIRECT_PATH', path)
  },
  async fetchSuggestions({ commit }, query) {
    try {
      const response = await this.$typesenseClient.multiSearch.perform({
        searches: [
          {
            q: query,
            collection: 'product_queries',
            query_by: 'q',
            sort_by: '_text_match:desc,count:desc',
            limit: 8,
          },
        ],
      })

      const suggestions = response.results[0]?.hits
        .map((hit) => hit?.document?.q)
        .filter((suggestion) => suggestion)

      commit('SET_SUGGESTIONS', suggestions)
    } catch (error) {
      commit('CLEAR_SUGGESTIONS')
    }
  },
  clearSuggestions({ commit }) {
    commit('CLEAR_SUGGESTIONS')
  },
  async getPopularSearches({
    commit,
    rootState: { storeCode: store, locale, substore },
  }) {
    let popularSearches = []

    const params = {
      locale,
      store,
      substore: substore.code,
    }

    try {
      popularSearches = await this.$axios.$get('/popular_search', {
        params,
      })

      popularSearches = popularSearches.map((res) => res?.term) || []
    } catch {
      commit('CLEAR_POPULAR_SEARCHES')
    }

    commit('SET_POPULAR_SEARCHES', popularSearches)
  },
}

export const getters = {
  lastCategories({ lastCategories }) {
    return lastCategories
  },
  isLoading({ isLoading }) {
    return isLoading
  },
  getSmartRedirectPath({ smartRedirectPath }) {
    return smartRedirectPath
  },
  getSuggestions({ suggestions }) {
    return suggestions
  },
  getSearchOptions({ popularSearches, suggestions }) {
    if (!suggestions || suggestions.length === 0) {
      return {
        label: i18n.t('search.popular'),
        items: popularSearches || [],
      }
    }
    return {
      label: i18n.t('search.suggestions'),
      items: suggestions || [],
    }
  },
  getPopularSearches({ popularSearches }) {
    return popularSearches
  },
}
