import { BLACKLISTED_WORDS_NBA } from './utils/blackListNBA'
/** Engine params */
export const GLOBAL_DEVICE_IS_ACTIVE = false
export const ENGINE_EXPORT_IMAGE_EVENT = 'exportImage'
export const ENGINE_INIT_PARAMS = {
  nameOfEventExportImg: ENGINE_EXPORT_IMAGE_EVENT,
  appMountId: 'e-app',
  modalEngineId: 'modal-engines',
  btnShowEngineId: 'btn_engine',
  liteMode: {
    previewClass: 'engine-preview-render',
    previewMountId: 'main-image',
    previewHeight: 500,
    inputsMountId: 'spree-e-inputs',
    exportMountId: 'spree-e-export',
  },
  events: {
    exportImage: ENGINE_EXPORT_IMAGE_EVENT,
  },
  viewData: {
    btnExportName: 'Done!',
    btnExportClass: 'btn btn--success btn--large btn--block',
    hasLnrCss: false,
    icons: false,
  },
}

// ALGOLIA
export const ALGOLIA_APP_ID = '1BBKKIFRNT'
export const ALGOLIA_API_KEY = '031c0e65de6d2265a7463caa4c5fa9f8'

// GTM
export const GTM_PRODUCTS_QUANTITY = 15

// Govinci
/**
 * Please, do NOT change this URL. Govinci images MUST be servede via ik to use cache.
 */
export const PREVIEW_HOST = 'https://ik.imagekit.io/gocase/govinci'

export const NO_IMAGE_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAJFBMVEX4+vvb4OTx9PXl6ezf4+f19/nt8PLz9vfq7fDi5unc4eTs7/GWhAgrAAACGUlEQVR4nO3b23KqMBiAUQEtsn3/961OtydIJEUmaXCtS7n5v5FTEHc7AAAAAAAAAAAAAAAAAAAAAAAAzvr2PX3pgNf6oXnf8Icjjyv0XZxKh8R8rRTYNF+lU8IOqwU2zaF0TNBa++jFsXRM0H7Fwn3pmKAVA5umdEzQhxXuT/3h0B7/bbbwdrpfevopGRIVnq/dZOHT1WzZfUCpiJdu040uZovuVcskzIhNt2g/LVIw5zrcENuwmcLJHdeS3bREwKzrcJO1z+YKJ99h/IZ138bOtCUCZl2HSz8OL/fXkTVXgfnnxaaLnkt/FhDhxPzjJ7hNNzoQY4fhdYUUTMw+fYr7eEn3NPclYCgx+/QpwvPF9tHHNW4gMffwSR4HbK8fxtYWz4v4aWL26VM8F5z68/cXXTuNn1JMEosUzInVJAROEwvMP++dwEli9ulTvBU4Tsw8e5r3AkeJWSdPFaoZ+lNq4HNixrnTBQK73eSC+OpZ7+H+aC7X0L8SDhwlvn6Y3VVW2P3f0KYG1lbY3ba0iYGVFXYPm9q0wLoKu6dtbVJgVYXdaGOb9ItZRYXjwPMyMeUnwXoKp4FpqilcGlhN4eRhW7JaChd/hQpLU5hAYWEfVDgsfrl0qKRwDaVjghTWX7j9dxO3/37pmrtp6ZSIzb/nvdtNnm8v9Gff1f+A/1tcbPw/MwAAAAAAAAAAAAAAAAAAAAAAALl8A7+jEA62Pbx2AAAAAElFTkSuQmCC'

// https://stamped.io/integrations
export const STAMPED_SCRIPT_SRC = 'https://cdn1.stamped.io/files/widget.min.js'

export const CURRENCIES = ['BRL', 'USD', 'EUR', 'GBP', 'CHF', 'AUD']
export const LOCALES = ['pt-BR', 'en', 'de', 'it', 'es', 'fr', 'nl', 'pt']
export const STORE_CODES = ['br', 'global']

export const STORES = [
  {
    code: 'br',
    locale: 'pt-BR',
    currency: 'BRL',
    hostRegexp: /gocase.com.br(:\d+)?$/,
  },
  {
    code: 'global',
    locale: 'en',
    currency: 'USD',
    hostRegexp: /gocase.com(:\d+)?$/,
  },
]

export const STORE_CREDENTIALS = {
  br: {
    stamped: {
      url: 'gocase.com.br',
      apiKey: 'pubkey-hb1m6LdSHCGcO65iPA95x0n8pJdhS5',
    },
    gtm: {
      id: 'GTM-PR54K9F',
    },
  },
  global: {
    stamped: {
      url: 'shop-gocase.com',
      apiKey: 'pubkey-N6Q727SNSTMMF2a42a9014li20wt17',
    },
    gtm: {
      id: 'GTM-N9NZK8W',
    },
  },
}

export const SOCIAL = {
  br: {
    facebook: 'gocasebr',
    instagram: 'gocasebr',
    tiktok: '@gocase',
    twitter: 'gocasebr',
    pinterest: 'gocasebr',
  },
  global: {
    facebook: 'shopgocase',
    instagram: 'gocase',
    tiktok: '@gocase',
    twitter: 'shopgocase',
    pinterest: 'shopgocase',
  },
}

export const LOCALE_NAME = {
  es: 'Español',
  pt: 'Portugues',
  it: 'Italiano',
  nl: 'Nederlands',
  de: 'Deutsch',
  fr: 'Français',
  en: 'English',
  'pt-BR': 'Português Brasileiro',
}

export const LOCALE_FLAG_STRING = {
  es: 'flag-spain',
  pt: 'flag-portugal',
  it: 'flag-italy',
  nl: 'flag-netherlands',
  de: 'flag-germany',
  fr: 'flag-france',
  en: 'flag-usa',
  'pt-BR': 'flag-brazil',
}

export const CURRENCY_SYMBOL = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  CHF: '',
  AUD: '$',
  BRL: 'R$',
}

/* COOKIES */
export const LOCALE_COOKIE_NAME = 'v4_locale'
export const CURRENCY_COOKIE_NAME = 'v4_currency'

export const REWARDS = {
  APP: 'https://unpkg.com/@gocasebr/gorewards@latest/dist/rewardApp.app.js',
  HOST: 'https://rewards.gocase.com.br/api/v1',
  REFERRAL_WIDGET:
    'https://unpkg.com/@gocasebr/referral-widget@latest/dist/referralWidget.app.js',
}

export const EUROPEAN_CONTRIES = [
  'AL',
  'AD',
  'AM',
  'AT',
  'AZ',
  'BY',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'GE',
  'DE',
  'GR',
  'HU',
  'IS',
  'IE',
  'IT',
  'KZ',
  'XK',
  'LV',
  'LI',
  'LT',
  'LU',
  'MK',
  'MT',
  'MD',
  'MC',
  'CS',
  'NL',
  'NO',
  'PO',
  'PT',
  'RO',
  'RU',
  'SM',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'TR',
  'UA',
  'GB',
  'VA',
]

export const R_SHELFS = {
  de: {
    'People who bought': ['Leute, die ', 'gekauft haben, haben auch gekauft'],
    'People viewing': ['Leute, die ', 'sehen, mögen es auch'],
    'Similar products': 'Ähnliche Produkte',
    'Best Sellers and Flash Sales': 'Bestseller und Rabattprodukte',
    'You previously viewed': 'Sie haben zuvor angesehen',
  },
  fr: {
    'People who bought': ['les gens qui ont acheté ', ' ont également acheté'],
    'People viewing': ['les gens qui regardent ', ' ont également acheté'],
    'Similar products': 'Produits Similaires',
    'Best Sellers and Flash Sales': 'Meilleurs Vendeurs',
    'You previously viewed': 'vous avez déjà vu ceci',
    'People with similar':
      'les gens ayant des intérêts similaires ont également vu',
  },
  it: {
    'People who bought': [
      'Le persone che hanno visualizzato ',
      ' hanno quindi acquistato',
    ],
    'People viewing': ['Alle persone che visualizzano ', ' piace anche questo'],
    'Similar products': 'Prodotti Simili',
    'Best Sellers and Flash Sales': 'I più venduti e le vendite flash',
    'You previously viewed': 'Hai visto in precedenza',
    'People with similar':
      'Sono state visualizzate anche persone con interessi simili',
    'products in your favorite product':
      'I prodotti più venduti della tua categoria di prodotti preferita',
    'People who viewed': [
      'Le persone che hanno visualizzato ',
      ' hanno quindi acquistato',
    ],
    'similar to the items in your shopping':
      'Questi sono simili agli articoli nel tuo carrello',
    'These are similar to the items in your trolley':
      'Questi sono simili agli articoli nel tuo carrello',
  },
}

export const BLACKLISTED_WORDS = [
  ...BLACKLISTED_WORDS_NBA,
  'Ym9sc29uYXJv',
  'YjBsc29uYXJv',
  'Ym9sczBuYXJv',
  'YjBsczBuNHIw',
  'Ym9sc29uNHJv',
  'Ym9sc29uYXIw',
  'YjBsczBuNHJv',
  'Ym9sc29uNHIw',
  'YjBsc29uNHJv',
  'YjBsc29uYXIw',
  'Ym9sczBuNHJv',
  'Ym9sczBuNHIw',
  'bHVsYQ==',
  'bHVsNA==',
  'Ym9sc29taXRv',
  'bWl0bw==',
  'bTF0bw==',
  'bTF0MA==',
  'bWl0MA==',
  'Ym9sc29taXQw',
  'Ym9sczBtaXQw',
  'YjBsczBtaXQw',
  'YjBsczBtMXQw',
  'YjBsc29tMXQw',
  'YjBsc29tMXRv',
  'Ym9sc29tMXRv',
  'Ym9sczBtMXRv',
  'bmF6aXNtbw==',
  'bjR6aXNtbw==',
  'bmF6aXNt',
  'bjR6aXNt',
  'Y2lybyBnb21lcw==',
  'Y2lyMCBnMG1lcw==',
  'YzFybyBnMG1lcw==',
  'YzFyMCBnMG1lcw==',
  'YzFyMCBnb21lcw==',
  'YzFybyBnb21lcw==',
  'Y2lyMCBnb21lcw==',
  'Ym9sc29taW5pb24=',
  'dGFiZXQ=',
  'dDRiZXQ=',
  'dGFiM3Q=',
  'dDRiM3Q=',
  'ZXltYWVs',
  'ZXltYTNs',
  'ZXltNGVs',
  'bWFy52Fs',
  'bTRy5zRs',
  'bWFy5zRs',
  'bTRy52Fs',
  'bWFuemFubw==',
  'bWFuejRubw==',
  'bTRuemFubw==',
  'bTRuejRubw==',
  'bTRuejRuMA==',
  'bWFuemFuMA==',
  'bTRuemFuMA==',
  'bWFuejRuMA==',
  'dGhyb25pY2tl',
  'dGhyb25pY2sz',
  'dGhyMG5pY2tl',
  'TGF6YXJlbnRh',
  'U2V4bw==',
  'T3JnYXNtbw==',
  'UHV0YXJpYQ==',
  'dmFnYWJ1bmRh',
  'ZmlsaGEgZGEgcHV0YQ==',
  'dmFkaWE=',
  'c2FmYWRh',
  'YmlzY2F0ZQ==',
  'cXVlbmdh',
  'VmFnYWJ1bmRhZ2Vt',
  'UmFwYXJpZ2E=',
  'Y29ybm8=',
  'dmFnYWJ1bmRv',
  'U2FmYWRv',
  'TGl4bw==',
  'UGF1IG5vIGN1',
  'YXJyb21iYWRv',
  'ZmlsaG8gZGUgcmFwYXJpZ2E=',
  'ZmkgZGUgcXVlbmdh',
  'ZmkgZGUgcHV0YQ==',
  'ZmkgZGUgcmFwYXJpZ2E=',
  'TmlnZ2E=',
  'U2V1IGN1',
  'YnVjZXRh',
  'cHJpcXVpdG8=',
  'eGluaW4=',
  'cGlyb2Nh',
  'VmlhZG8=',
  'U2FmYWRv',
]
