import { SearchClient as TypesenseSearchClient } from 'typesense'

export default ({ $config: { typesenseHost, typesenseApiKey } }, inject) => {
  let typesenseClient = null

  const initializeTypesenseClient = () => {
    if (!typesenseClient) {
      try {
        typesenseClient = new TypesenseSearchClient({
          nodes: [
            {
              host: typesenseHost,
              port: '443',
              protocol: 'https',
            },
          ],
          apiKey: typesenseApiKey,
          connectionTimeoutSeconds: 2,
        })
      } catch (error) {}
    }
    return typesenseClient
  }

  const client = initializeTypesenseClient()
  inject('typesenseClient', client)
}
