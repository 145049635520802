export default () => {
  if (process.browser && 'serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/insider-sw-sdk.js')
      .then((registration) => {
        // TODO: remove this after homologation
        // eslint-disable-next-line no-console
        console.log('Service Worker registered successfully:', registration)
      })
      .catch((error) => {
        // TODO: remove this after homologation
        // eslint-disable-next-line no-console
        console.error('Failed to register Service Worker:', error)
      })
  }
}
