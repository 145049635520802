export const state = () => ({
  evaluatedExperiment: false,
})

export const mutations = {
  SET_EVALUATED_EXPERIMENT(state, boolean) {
    state.evaluatedExperiment = boolean
  },
}

export const actions = {
  updateEvaluatedExperiment({ commit }, boolean) {
    commit('SET_EVALUATED_EXPERIMENT', boolean)
  },
}

export const getters = {
  getEvaluatedExperiment({ evaluatedExperiment }) {
    return evaluatedExperiment
  },
}
